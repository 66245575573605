
















































import { Component, Mixins } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerTutorial from '@/components/ContainerTutorial/ContainerTutorial.vue';
import Footer from '@/components/Footer/Footer.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { DATA_TUTORIAL_BOOKS } from './ConstantTutorialsBooks';

import { OnboardingClass } from '@/mixins/Onboarding';
import { NavigationTutorials } from '@/mixins/NavigationTutorials';

@Component({
  components: {
    PageHeader,
    ContainerTutorial,
    Footer,
  },
})
export default class TutorialBooks extends Mixins(OnboardingClass, NavigationTutorials) {
  private isLoading = false;

  created() {
    this.saveDataTutorials(DATA_TUTORIAL_BOOKS);
  }

  mounted() {
    this.setBreadCrumbs();
  }

  async updateOnboardingView() {
    try {
      this.isLoading = true;
      await this.updateOnboarding(LIST_ONBOARDING_ID.BOOKS);
      await this.timerUpdateProfile(this.goToBooksPage);
    } catch (error) {
      console.error(error);

    } finally {
      this.isLoading = false;
    }
  }

  goToBooksPage() {
    let nameRoute = 'Books';

    if (this.$route.params?.path) nameRoute = 'BooksView';

    this.$router.push({
      name: nameRoute,
      params: {
        path: `${this.$route.params?.path || ''}`,
      },
    });
  }

  setBreadCrumbs() {
    const path = this.$route.params?.path;

    const redirect = path
      ? {
        title: 'Caderno ',
        to: `/caderno/${path}`,
      }
      : {
        title: 'Cadernos ',
        to: '/cadernos',
      };

    this.$breadcrumb.set([
      { title: redirect.title, to: redirect.to },
      { title: 'Tutorial ', to: null },
    ]);
  }
}
